/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import $ from 'jquery';

// eslint-disable-next-line react/prefer-stateless-function
class Bottommenu extends Component {
  render() {
    return (
      <div className="appBottomMenu">
      <a href="/" className="item active">
        <div className="col">
          <ion-icon name="home-outline" />
          <strong>Home</strong>
        </div>
      </a>
      <a href="/event" className="item">
        <div className="col">
          <ion-icon name="calendar-outline" />
          <strong>Events</strong>
        </div>
      </a>
      {/* <a href="https://shop.dswip.com" className="item">
        <div className="col">
          <ion-icon name="cart-outline" />
          <strong>Marketplace</strong>
        </div>
      </a> */}
      <a href="/profile" className="item">
        <div className="col">
          <ion-icon name="person-outline" />
          <strong>Profile</strong>
        </div>
      </a>
    </div>
    );
  }
}

export default Bottommenu;
