import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import Select from 'react-select';
import swal from 'sweetalert';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mtype from './comp/mtype';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Profilesetting extends Component {

  constructor(props){
    super(props);
    this.state = {file:null, data:[], datakab:[], datadistrict:[], verify:false, loggedin:true, load:false, city:"", district:"",mess:null, errortype:'success' }
  }

  componentDidMount(){
     this.token_otentikasi();
     this.load_kabupaten();
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
     const stts = await API.otentikasi();
     if (stts === false){ this.setState({ loggedin:false }) }else{
       this.load_user();
     }
  }

  load_user = async() => {
    axios.get(API.url+'customer/get/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      // console.log("Data load user : ",response.data.content.result);
        this.setState({ data: response.data.content.result, city:response.data.content.result.city, district:response.data.content.result.district});
        this.load_district();
    })
    .catch((error) => { 
        // error.response.status
        // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
        // swal(error.response.data.error, "", "error");
        // console.log(error);
    }); 
  }

  load_kabupaten = async() => {
    this.setState({ load:false });
    axios.get(API.url+'city/get_city_rj')
      .then((response) => {
          this.setState({ datakab: response.data.content.result, load:true });
      })
      .catch((error) => { 
          // console.log(error);
      }); 
  }

  handleChange = (selectedOption) => {
    this.setState({ city:selectedOption.value });
  };

  update = async() =>{
      
    let profession = document.getElementById("profession").value;
    let organization = document.getElementById("organization").value;
    let instagram = document.getElementById("instagram").value;
    let dob = document.getElementById("dob").value;
    let email = document.getElementById("email").value;
    let zip = document.getElementById("zip").value;
    let address = document.getElementById("address").value;

    let requestBody = {tprofession:profession,torganization: organization,tinstagram:instagram,taddress:address,tzip:zip,temail:email,tdob:dob,ccity:this.state.city }
    // console.log(requestBody);

    this.setState({ load:false })
    axios.post(API.url+'customer/update',qs.stringify(requestBody),{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
           this.setState({ mess: "Data update successfull", errortype: 'success' }, () => toastbox('ttoast',3000));

          // setTimeout(
          //   () => this.setState({ verify: true }), 
          //   3000
          // );
      })
      .catch((error) => { 
        console.log("Error : ", error);
        this.setState({ mess: "Failed to post data", errortype: 'error' }, () => toastbox('ttoast',3000));
      }); 
        // axios

  }

  handleFileUpload = async(event) =>{
        const file = event.target.files[0];
        if (!file) { this.setState({ mess: "No attached data", errortype: 'error' }, () => toastbox('ttoast',3000));}
        else{ 
          this.setState({ file });
          const formData = new FormData();
          formData.append('userfile', file);
          axios.post(API.url+'customer/upload_image',formData,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
          .then((response) => {
              this.token_otentikasi();
              this.setState({ mess: "Data uploaded successfull", errortype: 'success' }, () => toastbox('ttoast',3000));
          })
          .catch((error) => { 
            console.log("Error : ", error);
            this.setState({ mess: "Failed to upload data", errortype: 'error' }, () => toastbox('ttoast',3000));
          }); 
      }
  }

  render() {
    // console.log('Data property : ',this.state.user);
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.verify === true){ return <Redirect to={`/verify/${this.state.data.id}`}></Redirect>}

     // Custom styles for react-select
     const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'blue' : 'gray',
        '&:hover': {
          borderColor: 'blue',
        },
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
        padding: '5px',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensure dropdown is above other elements
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'lightgray' : 'black',
        '&:hover': {
          backgroundColor: '#161129',
        },
        color: state.isSelected ? 'black' : 'inherit',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'black',
      }),
    };

    return (
      <>
        {/* <Meta/> */}

        <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <a href="/profile" className="headerButton goBack">
                <ion-icon name="chevron-back-outline" />
              </a>
            </div>
          </div>

          <div id="appCapsule">
            <div className="section mt-2 text-center">
             <h3 className="text-center">My Profile</h3>
            </div>

            <div className="section mt-3 text-center" style={{ border:'0px solid black', height:"120px" }}>
            <div className="avatar-section" style={{ margin: '0 !important', padding: '0' }}>
              <label htmlFor="file-upload" className="custom-file-upload" style={{ cursor: 'pointer', margin: '0 !important' }}>
                <img
                  src={this.state.data.image_url}
                  alt="avatar"
                  className="imaged w100 rounded"
                  style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '0 !important' }}
                />
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: 'none', margin: '0 !important' }}
                onChange={this.handleFileUpload}
              />
            </div>
          </div>

            {/* batas */}
           
            <form>
            <div className="section mb-5 p-2">
              
                <div className="card">
                  <div className="card-body">

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Name </label>
                        <input type="text" className="form-control" id="name" placeholder="Your name" defaultValue={this.state.data.first_name}  required readOnly />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Phone </label>
                        <input type="number" className="form-control" id="phone" placeholder="Your phone no" defaultValue={this.state.data.phone1} readOnly />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">E-mail</label>
                        <input type="email" className="form-control" id="email" placeholder="Your e-mail" defaultValue={this.state.data.email} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">Address</label>
                        {/* <input type="email" className="form-control" id="temail" placeholder="Your e-mail" /> */}
                        <textarea className="form-control" style={{height: '100px'}} id="address" placeholder="Address" defaultValue={this.state.data.address}></textarea>
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Zip </label>
                        <input type="number" className="form-control" id="zip" placeholder="Your zip code" defaultValue={this.state.data.zip} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">City</label> <br/>
                        <Select
                          value={this.state.datakab.filter(({value}) => value === this.state.city)}
                          placeholder="..."
                          onChange={this.handleChange}
                          options={this.state.datakab}
                          styles={customStyles}
                          isDisabled={false}
                        />

                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Profession </label>
                        <input type="text" className="form-control" id="profession" placeholder="Profession" defaultValue={this.state.data.profession} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Organization </label>
                        <input type="text" className="form-control" id="organization" placeholder="Organization" defaultValue={this.state.data.organization} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Instagram </label>
                        <input type="text" className="form-control" id="instagram" placeholder="Instagram" defaultValue={this.state.data.instagram} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> DOB </label>
                        <input type="date" className="form-control" id="dob" placeholder="Your date of birth" defaultValue={this.state.data.dob} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="form-button-group transparent">
                  <button onClick={this.update} type="button" className="btn btn-primary btn-block btn-lg">Update Profile</button>
                </div>
              
            </div>
            </form>

        {/* toast */}
         <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}

          </div>

         
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Profilesetting;
